.ldsRing {
    display: inline-block;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

/* large size */
.ldsRing.large {
    width: 80px;
    height: 80px;
}

.ldsRing.large div {
    width: 64px;
    height: 64px;
}

/* medium size */
.ldsRing.medium {
    width: 60px;
    height: 60px;
}

.ldsRing.medium div {
    width: 44px;
    height: 44px;
}

/* small size */
.ldsRing.small {
    width: 40px;
    height: 40px;
}

.ldsRing.small div {
    width: 24px;
    height: 24px;
}


.ldsRing div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    margin: 8px;
    border: 4px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.ldsRing div:nth-child(1) {
    animation-delay: -0.45s;
}

.ldsRing div:nth-child(2) {
    animation-delay: -0.3s;
}

.ldsRing div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}